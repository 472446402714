/* eslint-disable @typescript-eslint/no-explicit-any */
import { Experiment, FinnWindow, InitialTrackerState, PulseInitWrapper, SDKConfigInput } from './types';
import { cleanUrl } from './urls';

export class Config {
    constructor(readonly window: FinnWindow) {}
}

export type Treatments = Record<string, number>;

export default class FinnPulseInitializer {
    private readonly pulse: PulseInitWrapper;
    private readonly win: FinnWindow;

    private readonly isLocalDeployment: boolean;
    private readonly isDev: boolean;
    private readonly providerId: string;

    private static readonly defaultProviderId = 'finn';
    private static readonly defaultBrand = 'FINN';
    private static readonly dataAppName = 'data-application-name';
    private static readonly pageStateTrackerName = 'pageStateTracker';
    private static readonly finnPulseOptionsMetaTag = 'FINN.pulseOptions';
    private static readonly finnPulseTrackerDataMetaTag = 'FINN.pulsePageTrackerData';
    private static readonly extendedProfileMetaTag = 'promotion.extendedProfile';
    private static readonly brandMetaTagName = 'nmp:tracking:brand';
    private static readonly loginIdMetaTagName = 'nmp:tracking:login-id';
    private static readonly spidIdMetaTagName = 'nmp:tracking:spid-id';
    private static readonly appNameMetaTagName = 'nmp:tracking:app-name';
    private static readonly isAuroraMetaTagName = 'nmp:tracking:aurora';
    private static readonly abtest_cookie_name = 'abtest';
    private static readonly abtest_cookie_domain = 'finn.no';
    private static readonly abtest_cookie_maxAgeInSeconds = 1800; // 30 minutes
    private static trackerEventSchemaVersion = 'http://schema.pulse.m10s.io/events/tracker-event.json/355.json';
    private static readonly xandrVendorName = 'xandr';
    private static readonly googleVendorName = 'delta';

    public constructor(isLocalDeployment: boolean, isDev: boolean, pulse: PulseInitWrapper, config?: Config) {
        // isLocalDeployment is used for setting deployStage in init() call when testing tracking on localhost.
        // deployStage in init() call is independent of .deployStage in the tracking payload, so we
        // need to set this separately to not get blocked by CORS protection on localhost.
        this.isLocalDeployment = isLocalDeployment;

        this.isDev = isDev;
        this.pulse = pulse;

        if (config && config.window) {
            this.win = config.window;
        } else {
            this.win = window;
        }

        if (this.isDev) {
            this.win.document.cookie = '_pulse2debug=true;SameSite=Lax';
        }

        this.providerId = this.getProviderFor(this.getBrand());

        this.initializeTracker();
    }

    private initializeTracker(): void {
        const initialTrackerState = this.generateInitialTrackerState();
        const initialPageTrackerState = this.generateInitialPageTrackerState(initialTrackerState);

        const initialise = () => {
            const skdConfig = this.getSkdConfig();

            this.pulse.init(this.providerId, skdConfig, initialTrackerState);
            this.pulse.init(
                this.providerId,
                skdConfig,
                initialPageTrackerState,
                FinnPulseInitializer.pageStateTrackerName,
            );
        };

        initialise();
    }

    private getSkdConfig(): SDKConfigInput {
        // Pass both 'xandr' and 'delta' as advertising vendors to generate PPIDs for both vendors.
        // 'xandr' will eventually be removed, and 'delta' renamed to 'google'.
        const options: SDKConfigInput = {};
        options.vendors = [FinnPulseInitializer.xandrVendorName, FinnPulseInitializer.googleVendorName];

        // Set dev deployStage when running on a local deployment to use the PRE env for collection endpoint.
        // Without this, requests to the PRO collection endpoint are blocked by CORS protection.
        // We don't want to use PRE for dev, because events aren't forwarded to the sinks in Pulse for PRE, and the events are not blocket by CORS protection for the dev environments.
        if (this.isLocalDeployment) {
            options.deployStage = 'dev';
        }

        try {
            // Pass cached consents to Pulse for repeat visitors
            // See https://github.schibsted.io/spt-privacy/sourcepoint#pulse-installation
            if (
                (window as any)._tcf_ &&
                typeof (window as any)._tcf_.getCachedOrDefaultConsentsForPulse === 'function'
            ) {
                options.consents = (window as any)._tcf_.getCachedOrDefaultConsentsForPulse();
                options.requireAdvertisingOptIn = true; // required when you must support opt-out from targeted advertising, i.e. you have ads on the site
            }
        } catch (e) {
            console.warn(
                'finn-pulse-init: exception during initialisation. Setting SKDConfig without cached consents.',
                e,
            );
        }

        if ('DBA' === this.getBrand()) {
            options.useGlobalEventBus = true;
        }

        return options;
    }

    private getMetaContent(metaTagName: string): string | null {
        const metaTag = this.getMetaElement(metaTagName);
        return metaTag?.content || null;
    }

    private getMetaElement(metaName: string): HTMLMetaElement | null {
        return this.win.document.querySelector(`meta[name="${metaName}"]`);
    }

    private getTopbarDataServiceElement(): Element | null {
        return this.win.document.querySelector('topbar-data-service');
    }

    private getPulseOptionsFromMeta(): Record<string, unknown> {
        const element: HTMLMetaElement | null = this.getMetaElement(FinnPulseInitializer.finnPulseOptionsMetaTag);
        const extendedProfile: HTMLMetaElement | null = this.getMetaElement(
            FinnPulseInitializer.extendedProfileMetaTag,
        );

        let pulseOptions: any = {};

        if (element) {
            pulseOptions = JSON.parse(decodeURIComponent(element.content));
        }

        if (extendedProfile?.content === 'true') {
            pulseOptions.hasExtendedProfile = true;
        }

        if (pulseOptions.vertical) {
            pulseOptions.vertical['@type'] = 'MarketplaceVertical';
        }

        return pulseOptions;
    }

    private getPulseTrackerDataFromMeta(): Record<string, unknown> {
        const element: HTMLMetaElement | null = this.getMetaElement(FinnPulseInitializer.finnPulseTrackerDataMetaTag);
        const extendedProfile: HTMLMetaElement | null = this.getMetaElement(
            FinnPulseInitializer.extendedProfileMetaTag,
        );
        let pulseData: any = {};

        if (element) {
            pulseData = JSON.parse(decodeURIComponent(element.content));

            if (pulseData.object?.id && pulseData.object?.type === 'ClassifiedAd') {
                pulseData.object.contentId = pulseData.object.id;
            }
        }

        if (extendedProfile?.content === 'true' && pulseData.object) {
            pulseData.object.hasExtendedProfile = true;
        }

        if (pulseData.vertical) {
            pulseData.vertical['@type'] = 'MarketplaceVertical';
        }

        return pulseData;
    }

    private generateInitialPageTrackerState(initialTrackerState: InitialTrackerState): InitialTrackerState {
        const trackerData = this.getPulseTrackerDataFromMeta();
        if (trackerData && Object.keys(trackerData).length > 0) {
            return {
                ...initialTrackerState,
                ...trackerData,
            };
        }

        const pulseOptions = this.getPulseOptionsFromMeta();
        if (pulseOptions && Object.keys(pulseOptions).length > 0) {
            return {
                ...initialTrackerState,
                ...addPulseOption('vertical', pulseOptions.vertical),
                object: {
                    ...initialTrackerState.object,
                    ...(pulseOptions.contentType === 'ClassifiedAd' && { contentId: pulseOptions.contentId }),
                    ...addPulseOption('type', pulseOptions.contentType),
                    ...addPulseOption('id', pulseOptions.contentId),
                    ...addPulseOption('category', pulseOptions.category),
                    ...addPulseOption('tags', pulseOptions.tags),
                    ...addPulseOption('location', pulseOptions.contentLocation),
                    ...addPulseOption('filters', pulseOptions.filters),
                    ...addPulseOption('custom', pulseOptions.objectCustom),
                    ...addPulseOption('pageType', pulseOptions.pageType),
                    ...addPulseOption('hasExtendedProfile', pulseOptions.hasExtendedProfile),
                },
            };
        }

        return initialTrackerState;
    }

    private generateInitialTrackerState(): InitialTrackerState {
        // Provider and deployTag - static.
        const initialTrackerState: InitialTrackerState = {
            // We want to set the schema version explicitly,
            // and not use the default (and lagging) values from
            // https://github.schibsted.io/spt-dataanalytics/pulse-event-builders.
            // If we make any schema change in the event-formats repository
            // (and by that bumping the schema versions),
            // we easily want to be able to bump the schema version here,
            // so that events validate when using the schema validator.
            schema: FinnPulseInitializer.trackerEventSchemaVersion,
            deployTag: `finn-pulse-init-PACKAGE_VERSION`,
            provider: {
                product: `www.${this.getBrand().toLowerCase()}`,
                productType: 'ResponsiveWeb',
            },
        };

        // deployStage - Implicit 'pro' if not set, dev otherwise.
        if (this.isDev) {
            initialTrackerState.deployStage = 'dev';
        }

        // Provider - Application name (if available).
        const applicationName = this.getApplicationName();
        if (applicationName) {
            initialTrackerState.provider.component = applicationName;
        }

        const isAurora = this.getMetaContent(FinnPulseInitializer.isAuroraMetaTagName);
        if (isAurora === '1') {
            initialTrackerState.provider.platformName = 'Aurora';
        }

        // Device - Finn device id.
        const finnDeviceId = this.getFinnDeviceId();
        if (finnDeviceId && finnDeviceId !== '') {
            initialTrackerState.device = {
                additionalDeviceIds: [
                    {
                        '@id': `sdrn:${this.providerId}:device:id:${finnDeviceId}`,
                        component: this.providerId,
                    },
                ],
            };
        }

        // Actor - Schibsted Account Id & Finn login id.
        const schAccountId = this.getSchibstedAccountId();
        if (schAccountId && schAccountId !== '') {
            initialTrackerState.actor = {
                id: schAccountId,
                realm: BrandToSpidRealmMap.get(this.getBrand()) || 'spid.no',
            };

            const additionalIds = [];

            const finnLoginId = this.getFinnLoginId();
            if (finnLoginId && finnLoginId !== '') {
                additionalIds.push({
                    '@id': `sdrn:${this.providerId}:user:userid:${finnLoginId}`,
                    component: this.providerId,
                });
            }

            // TODO: are we actually using orgId?
            const finnOrgId = this.getFinnOrgId();
            if (finnOrgId && finnOrgId !== '') {
                additionalIds.push({
                    '@id': `sdrn:${this.providerId}:user:orgid:${finnOrgId}`,
                    component: `${this.providerId}`,
                });
            }

            if (additionalIds.length > 0) {
                initialTrackerState.actor.additionalIds = additionalIds;
            }
        }

        this.updateAbtestCookieFromPodlets();

        // Experiments - A/B Test data from Unleash cookie.
        const experiments = this.getExperiments();
        if (experiments && experiments.length > 0) {
            initialTrackerState.experiments = experiments;
        }

        // Object - clean selected URLs.
        const url = this.win.document.URL;
        const urlCleaned = cleanUrl(url);
        if (urlCleaned) {
            initialTrackerState.object = {
                url: urlCleaned,
            };
        }

        const title = this.win.document.title;
        const titleCleaned = cleanTitle(title);
        if (titleCleaned) {
            initialTrackerState.object = {
                ...initialTrackerState.object,
                name: titleCleaned,
            };
        }

        return initialTrackerState;
    }

    private getApplicationName(): string | null {
        const appName = this.getMetaContent(FinnPulseInitializer.appNameMetaTagName);
        if (appName !== null) {
            return appName;
        }
        return this.getAttributeValue(FinnPulseInitializer.dataAppName);
    }

    private getFinnDeviceId(): string | null {
        return this.getCookie('USERID');
    }

    private getSchibstedAccountId(): string | undefined {
        const spidId = this.getMetaContent(FinnPulseInitializer.spidIdMetaTagName);
        if (spidId !== null) {
            return spidId;
        }

        const spidIdFromTopbar = this.getTopbarDataServiceElement()?.attributes.getNamedItem('spid-id');
        if (spidIdFromTopbar) {
            return spidIdFromTopbar.value;
        }

        return this.getMetaElement('spidId')?.content;
    }

    private getFinnLoginId(): string | undefined {
        const loginId = this.getMetaContent(FinnPulseInitializer.loginIdMetaTagName);
        if (loginId !== null) {
            return loginId;
        }

        const loginInFromTopbar = this.getTopbarDataServiceElement()?.attributes.getNamedItem('login-id');
        if (loginInFromTopbar) {
            return loginInFromTopbar.value;
        }

        return this.getMetaElement('FINN.userid')?.content;
    }

    private getFinnOrgId(): string | undefined {
        return this.getMetaElement('orgid')?.content;
    }

    private updateAbtestCookieFromPodlets(): void {
        const unleashToggles = this.win.experiments;

        if (!Array.isArray(unleashToggles) || unleashToggles.length < 1) {
            return;
        }

        let abTestJson: any = this.getAbtestCookie();

        if (!abTestJson) {
            abTestJson = {};
        }

        unleashToggles?.forEach((element) => {
            const [name, variant] = element.experiment.split('_');
            //abTestJson[element.experiment] = element.timestamp;
            abTestJson = this.setTreatment(abTestJson, name, variant, element.timestamp);
        });

        this.win.document.cookie = `${FinnPulseInitializer.abtest_cookie_name}=${encodeURIComponent(
            JSON.stringify(abTestJson),
        )};domain=${FinnPulseInitializer.abtest_cookie_domain};max-age=${
            FinnPulseInitializer.abtest_cookie_maxAgeInSeconds
        };path=/`;
    }

    private setTreatment(abTestJson: any, testKey: string, treatment: string, timestamp: number): Treatments {
        const validTreatments = this.removeExistingTreatment(abTestJson, testKey);
        const testGroup = `${testKey}_${treatment}`;
        validTreatments[testGroup] = timestamp;
        return validTreatments;
    }

    private removeExistingTreatment(abTestJson: any, testKey: string): Treatments {
        return Object.keys(abTestJson).reduce((obj, key) => {
            if (!key.startsWith(`${testKey}_`)) {
                return { ...obj, [key]: abTestJson[key] };
            }

            return obj;
        }, {});
    }

    private getExperiments(): Experiment[] | undefined {
        const abTestJson: any = this.getAbtestCookie();

        if (!abTestJson) {
            return;
        }

        const experiments: Experiment[] = [];

        Object.keys(abTestJson).forEach((key) => {
            if (!key) {
                return;
            }

            // We currently can't reliably deduce the variant from
            // the value found in the 'abtest' cookie, since '_'
            // also sometimes is used in toggle names. Therefore (for now)
            // set variant to an empty string (it's required in the schema)
            // and use the complete value in the 'id'.
            experiments.push({
                platform: this.providerId,
                id: key,
                variant: '',
            });
        });

        return experiments;
    }

    private getAbtestCookie(): string | undefined {
        const abTestData = this.getCookie('abtest');

        if (!abTestData || abTestData === '' || abTestData.charAt(0) !== '{') {
            return;
        }

        let abTestJson;
        try {
            abTestJson = JSON.parse(abTestData);
        } catch (e) {
            return;
        }

        return abTestJson;
    }

    private getCookie(key: string): string | null {
        if (!key || !this.win.document.cookie) {
            return null;
        }
        return (
            decodeURIComponent(
                this.win.document.cookie.replace(
                    new RegExp(
                        `(?:(?:^|.*;)\\s*${encodeURIComponent(key).replace(
                            /[-.+*]/g,
                            '\\$&',
                        )}\\s*\\=\\s*([^;]*).*$)|^.*$`,
                    ),
                    '$1',
                ),
            ) || null
        );
    }

    private getAttributeValue(attributeName: string): string | null {
        try {
            let attributeValue;
            if (this.win.document.currentScript) {
                attributeValue = this.win.document.currentScript.getAttribute(attributeName);
            }
            if (!attributeValue) {
                const el = this.win.document.querySelector(`[${attributeName}]`);
                if (el) {
                    attributeValue = el.getAttribute(attributeName);
                }
            }
            return attributeValue ?? null;
        } catch (e) {
            return null;
        }
    }

    /**
     * @param {string} brand The brand name
     * @returns {string} Provider ID, 'finn' if brand is unknown.
     */
    public getProviderFor(brand: string): string {
        if (this.isDev) {
            return BrandToProviderMapDev.get(brand) || FinnPulseInitializer.defaultProviderId;
        }
        return BrandToProviderMap.get(brand) || FinnPulseInitializer.defaultProviderId;
    }

    /**
     * Gets brand name from meta tag, or hostname if meta tag doesn't exist.
     *
     * @returns {string} Brand name, 'FINN' if brand is unknown
     */
    public getBrand(): string {
        const brand = this.getMetaContent(FinnPulseInitializer.brandMetaTagName);

        return brand?.toUpperCase() || FinnPulseInitializer.defaultBrand;
    }
}

function cleanTitle(dirty: string): string {
    return dirty.replace(/^\([0-9]+\)\s+/i, '');
}

function addPulseOption(name: string, option: any) {
    return option && { [name]: option };
}

const BrandToProviderMap = new Map([
    ['FINN', 'finn'],
    ['TORI', 'torifi'],
    ['DBA', 'dba-dk'],
    ['BLOCKET', 'blocket'],
]);

const BrandToProviderMapDev = new Map([
    ['FINN', 'finndev'],
    ['TORI', 'torifi'],
    ['DBA', 'dba-dk'],
    ['BLOCKET', 'blocket'],
]);

const BrandToSpidRealmMap = new Map([
    ['FINN', 'spid.no'],
    ['TORI', 'schibsted.fi'],
    ['DBA', 'schibsted.dk'],
    ['BLOCKET', 'schibsted.com'],
]);
